<template>
  <div v-loading="isLoading">
    <wxlogin
      v-if="isShowCode"
      :appid="appid"
      :scope="'snsapi_login'"
      :theme="'black'"
      :redirect_uri="encodeURIComponent(`${url}/#/callBack`)"
      :href="'data:text/css;base64,LmltcG93ZXJCb3ggLnRpdGxlIHsKICBkaXNwbGF5OiBub25lOwp9Ci5pbXBvd2VyQm94IC5zdGF0dXMuc3RhdHVzX2Jyb3dzZXIgewogIGRpc3BsYXk6IG5vbmU7Cn0KLndycF9jb2Rlewp3aWR0aDoyNDBweDsKaGVpZ2h0OiAyNDBweDsKbWFyZ2luLWxlZnQ6MzBweDsKYmFja2dyb3VuZDogbGluZWFyLWdyYWRpZW50KHRvIGxlZnQsICMyMzg1RkYsICMyMzg1RkYpIGxlZnQgdG9wIG5vLXJlcGVhdCwgCiAgICAgICAgICAgICAgICBsaW5lYXItZ3JhZGllbnQodG8gYm90dG9tLCAjMjM4NUZGLCAjMjM4NUZGKSBsZWZ0IHRvcCBuby1yZXBlYXQsIAogICAgICAgICAgICAgICAgbGluZWFyLWdyYWRpZW50KHRvIGxlZnQsICMyMzg1RkYsICMyMzg1RkYpIHJpZ2h0IHRvcCBuby1yZXBlYXQsCiAgICAgICAgICAgICAgICBsaW5lYXItZ3JhZGllbnQodG8gYm90dG9tLCAjMjM4NUZGLCAjMjM4NUZGKSByaWdodCB0b3Agbm8tcmVwZWF0LCAKICAgICAgICAgICAgICAgIGxpbmVhci1ncmFkaWVudCh0byBsZWZ0LCAjMjM4NUZGLCAjMjM4NUZGKSBsZWZ0IGJvdHRvbSBuby1yZXBlYXQsCiAgICAgICAgICAgICAgICBsaW5lYXItZ3JhZGllbnQodG8gYm90dG9tLCAjMjM4NUZGLCAjMjM4NUZGKSBsZWZ0IGJvdHRvbSBuby1yZXBlYXQsCiAgICAgICAgICAgICAgICBsaW5lYXItZ3JhZGllbnQodG8gbGVmdCwgIzIzODVGRiwgIzIzODVGRikgcmlnaHQgYm90dG9tIG5vLXJlcGVhdCwKICAgICAgICAgICAgICAgIGxpbmVhci1ncmFkaWVudCh0byBsZWZ0LCAjMjM4NUZGLCAjMjM4NUZGKSByaWdodCBib3R0b20gbm8tcmVwZWF0OwogICAgYmFja2dyb3VuZC1zaXplOiAxcHggMTJweCwgMTJweCAxcHgsIDFweCAxMnB4LCAxMnB4IDFweDsgCn0KLmltcG93ZXJCb3ggLnFyY29kZSB7CiAgYm9yZGVyOiAxcHggc29saWQgI0UxRThGMTsKICB3aWR0aDogMjIwcHg7CiAgaGVpZ2h0OiAyMjBweDsKbWFyZ2luOjlweDsKfQouaW1wb3dlckJveCAuc3RhdHVzewogIGRpc3BsYXk6IG5vbmU7Cn0KLmltcG93ZXJCb3ggLnN0YXR1c190eHR7CiAgY29sb3I6ICNmZmY7Cn0='"
      rel="external nofollow"
      :state="12345"
      style="height:260px"
    />
  </div>
</template>

<script>
import wxlogin from '@/components/vue-wxlogin/src/components/vue-wxlogin.vue'
import axios from 'axios'
export default {
  name: 'DashboardAdmin',
  components: { wxlogin },
  data() {
    return {
      url: '',
      appid: '',
      isShowCode: false,
      isLoading: true
    }
  },
  created() {
    // this.url = json.url
    axios.get('./json/data.json').then(res => {
      this.url = res.data.url
      this.appid = res.data.appid
      this.isShowCode = true
      this.isLoading = false
    })
  },
  methods: {

  }
}
</script>

<style lang="less" scoped>
// .dashboard-editor-container {
//   padding: 32px;
//   background-color: rgb(240, 242, 245);
//   position: relative;

//   .github-corner {
//     position: absolute;
//     top: 0px;
//     border: 0;
//     right: 0;
//   }

//   .chart-wrapper {
//     background: #fff;
//     padding: 16px 16px 0;
//     margin-bottom: 32px;
//   }
// }
// .wrp_code{
//   img{
//     border: 3px solid #00EAFF;
//     width: 220px;
//     height: 220px;
//   }
// }
</style>
